import { createContext } from "react";

import { ShopConfig } from "@utils/ssr";

export const defaultContext: ShopConfig["mainMenu"] = {
  __typename: "Menu",
  items: [],
};

export const MainMenuContext =
  createContext<ShopConfig["mainMenu"]>(defaultContext);

MainMenuContext.displayName = "MainMenuContext";
