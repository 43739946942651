/* eslint-disable global-require */
import { paths } from "@paths";
import { ssrMode } from "@temp/constants";

export const PROVIDERS = {
  DUMMY: {
    label: "Dummy",
  },
  STRIPE: {
    label: "Stripe",
  },
};

export const SOCIAL_MEDIA = [
  {
    ariaLabel: "facebook",
    href: "https://www.facebook.com/thevolte/",
    path: require("../images/facebook-icon.svg"),
  },
  {
    ariaLabel: "instagram",
    href: "https://www.instagram.com/thevolte/",
    path: require("../images/instagram-icon.svg"),
  },
  {
    ariaLabel: "twitter",
    href: "https://twitter.com/the_volte",
    path: require("../images/twitter-icon.svg"),
  },
];
export const META_DEFAULTS = {
  custom: [],
  description:
    "Dress hire grows your wardrobe with over 20,000 new, chic designer options. We help women get the perfect dress for every event on their calendar.",
  image: "",
  title: "Dress Hire | Australia's Largest Dress Hire Selection",
  type: "website",
  url: !ssrMode ? window.location.origin : "",
};

export const FOOTER_ABOUT_US = [
  {
    id: "fAbout",
    link: "/about-us",
    name: "About The Volte",
  },
  {
    id: "fRenting",
    link: "/faq",
    name: "How Renting works",
  },
  {
    id: "fLending",
    link: "/faq",
    name: "How Lending works",
  },
  {
    id: "fBlog",
    link: "https://thevolte.blog/",
    name: "Blog",
  },
  {
    id: "fCareers",
    link: "/careers",
    name: "Careers",
  },
  {
    id: "fPartnerPortal",
    name: "Partners",
    url: paths.partners,
  },
  {
    id: "fStatusCheck",
    name: "System Status",
    url: "https://status.thevolte.com/",
    target: "_blank",
    rel: "nofollow",
  },
];

export const FOOTER_CUSTOMER_CARE = [
  {
    id: "fContactUS",
    link: "/contact",
    name: "Contact Us",
  },
  {
    id: "fReturning",
    link: "/faq",
    name: "Returning Your Rentals",
  },
  {
    id: "fFaq",
    link: "/faq",
    name: "FAQ",
  },
  {
    id: "fTerms",
    link: "/terms-of-service",
    name: "Terms of Service",
  },
  {
    id: "fPolicies",
    link: "/privacy-policy",
    name: "Privacy Policy",
  },
];

export const DRESSES_NEAR_YOU = [
  {
    id: "dress-sydney",
    link: "/dress-hire-sydney",
    name: "Dress Hire Sydney",
  },
  {
    id: "dress-melbourne",
    link: "/dress-hire-melbourne",
    name: "Dress Hire Melbourne",
  },
  {
    id: "dress-brisbane",
    link: "/dress-hire-brisbane",
    name: "Dress Hire Brisbane",
  },
  {
    id: "dress-perth",
    link: "/dress-hire-perth",
    name: "Dress Hire Perth",
  },
  {
    id: "dress-adelaide",
    link: "/dress-hire-adelaide",
    name: "Dress Hire Adelaide",
  },
  {
    id: "dress-canberra",
    link: "/dress-hire-canberra",
    name: "Dress Hire Canberra",
  },
];
