import { Box, Grid, GridItem } from "@chakra-ui/react";
import Link from "next/link";
import * as React from "react";
import { ReactSVG } from "react-svg";
import KlaviyoSubscriber from "src/components/organisms/KlaviyoSubscriber";

import { CustomLink } from "@components/atoms";
import { SocialMediaIcon } from "@components/legacy";

import {
  DRESSES_NEAR_YOU,
  FOOTER_ABOUT_US,
  FOOTER_CUSTOMER_CARE,
  SOCIAL_MEDIA,
} from "../../../core/config";

import logoImg from "images/the_volte_logo_black.svg";

export const Footer: React.FC = () => (
  <Box
    bgColor="tertiary"
    color="white"
    padding={["4rem 1rem 1rem 1rem", null, null, "4rem 3rem"]}
  >
    <>
      <Grid
        w="full"
        templateColumns={{
          base: "repeat(1, 1fr)",
          lg: "repeat(5, 1fr)",
        }}
        gap={6}
      >
        <GridItem paddingRight="10%">
          <KlaviyoSubscriber />
        </GridItem>

        <GridItem w="100%" pb="1rem">
          <Box fontWeight="600" mb="1rem">
            ABOUT US
          </Box>
          <Box>
            {FOOTER_ABOUT_US.map((item) => (
              <Box
                key={item.name}
                my="0.3rem"
                fontSize={["0.8rem", null, null, "1rem"]}
              >
                <CustomLink
                  path={item.link}
                  url={item.url}
                  rel={item?.rel}
                  target={item?.target}
                >
                  {item.name}
                </CustomLink>
              </Box>
            ))}
          </Box>
        </GridItem>
        <GridItem w="100%" pb="1rem">
          <Box fontWeight="600" mb="1rem">
            CUSTOMER CARE
          </Box>
          <Box>
            {FOOTER_CUSTOMER_CARE.map((item) => (
              <Box
                key={item.name}
                my="0.3rem"
                fontSize={["0.8rem", null, null, "1rem"]}
              >
                <Link href={item.link}>{item.name}</Link>
              </Box>
            ))}
          </Box>
        </GridItem>
        <GridItem w="100%" pb="1rem">
          <Box fontWeight="600" mb="1rem">
            DRESSES NEAR YOU
          </Box>
          <Box>
            {DRESSES_NEAR_YOU.map((item) => (
              <Box
                key={item.name}
                my="0.3rem"
                fontSize={["0.8rem", null, null, "1rem"]}
              >
                <Link href={item.link}>{item.name}</Link>
              </Box>
            ))}
          </Box>
        </GridItem>
        <GridItem w="100%" pb="1rem">
          <Box
            display="flex"
            alignItems={[null, null, null, "center"]}
            justifyContent="start"
            flexDirection={["column", null, null, "row"]}
          >
            <Box mr="1rem">FOLLOW&nbsp;US</Box>
            <Box display="flex" mt={["1rem", null, null, "0"]}>
              {SOCIAL_MEDIA.map((medium) => (
                <Box key={medium.ariaLabel} mr="1rem">
                  <SocialMediaIcon medium={medium} key={medium.ariaLabel} />
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            mt="1.5rem"
            width={["60%", "50%", "50%", "40%", null, "100%"]}
            marginLeft="-15px"
          >
            <ReactSVG src={logoImg.src} />
          </Box>
          <Box mt="1rem">
            {`The Volte ${new Date().getFullYear()}. All rights reserved.`}
          </Box>
        </GridItem>
      </Grid>
    </>
  </Box>
);
